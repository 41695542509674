import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { resolve } from 'url';

@Injectable({
  providedIn: 'root'
})
export class AlertsToastsService {

  alertElement: HTMLIonAlertElement;
  toastElement: HTMLIonToastElement;
  constructor(private alertCtrl: AlertController, private toast: ToastController) { }

  presentAlert(title: string, subTitle: string, ok_text?: string, cancel_text?: string) {
    
    return new Promise((resolve, reject) => {
      this.alertCtrl.create({
        message: subTitle || '',
        header: title || '',
        animated: true,
        buttons: [
          {
            text: cancel_text || '',
            handler: _ => resolve(false)
          },
          {
            text: ok_text || 'OK',
            handler: _ => resolve(true)
          },
          
        ]
      }).then((alertElement) => {
        this.alertElement = alertElement;
        this.alertElement.present();
      });
    });
  }

  showToast(message: string, duration?: number) {
    return new Promise((resolve, reject) => {
      this.toast.create({
        message: message,
        duration: duration || 3000,
        animated: true
      }).then((toastElement) => {
        this.toastElement = toastElement;
        this.toastElement.present();
        resolve(true);
      });
    });
  }


}
