import { Directive,Input } from '@angular/core';

@Directive({
  selector: '[data-video-host,data-kvideo-id,data-video-params]'
})
export class HtmlDirectivesDirective {
  @Input('data-video-host') a : any;
  
  @Input('data-kvideo-id') b
  : any;
  @Input('data-video-params') c
  : any;
  constructor() { }

}
