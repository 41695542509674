import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'splash', pathMatch: 'full' },
  { path: '.', redirectTo: 'splash', pathMatch: 'full' },
  { path: './', redirectTo: 'splash', pathMatch: 'full' },
  { path: './index.html', redirectTo: 'splash', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./otp/otp.module').then( m => m.OtpPageModule)
  },
  {
    path: 'videosingle',
    loadChildren: () => import('./videosingle/videosingle.module').then( m => m.VideosinglePageModule)
  },
  {
    path: 'playlistcontent',
    loadChildren: () => import('./playlistcontent/playlistcontent.module').then( m => m.PlaylistcontentPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then( m => m.TestPageModule)
  },
  {
    path: 'test-second',
    loadChildren: () => import('./test-second/test-second.module').then( m => m.TestSecondPageModule)
  },
  {
    path: 'daily-question',
    loadChildren: () => import('./daily-question/daily-question.module').then( m => m.DailyQuestionPageModule)
  },
  {
    path: 'thanks',
    loadChildren: () => import('./thanks/thanks.module').then( m => m.ThanksPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'answers',
    loadChildren: () => import('./answers/answers.module').then( m => m.AnswersPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'check-location-data',
    loadChildren: () => import('./check-location-data/check-location-data.module').then( m => m.CheckLocationDataPageModule)
  },  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'vaccine-info',
    loadChildren: () => import('./vaccine-info/vaccine-info.module').then( m => m.VaccineInfoPageModule)
  },
  {
    path: 'vaccine-dose',
    loadChildren: () => import('./vaccine-dose/vaccine-dose.module').then( m => m.VaccineDosePageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
