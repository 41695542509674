import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JavaScriptLoaderService {

  dynamicScripts = ["https://assets.kpoint.com/orca/media/embed/player-cdn.js"];
  constructor() { }

  public loadScript() {
    ////console.log('start loading js lib');
    let isFound = false;
    let scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      // if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes(this.dynamicScripts[i])) {
        isFound = true;
      }
    }

    ////console.log('js lib found : '+isFound);

    if (!isFound) {

      for (let i = 0; i < this.dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = this.dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
        ////console.log('js downloaded : '+this.dynamicScripts[i]);
      }

    }
  }
}
