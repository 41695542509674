import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { JavaScriptLoaderService } from './providers/utils/java-script-loader.service';
import { Router } from '@angular/router';
import { RequestModelService } from './providers/request/request-model.service';
import { HmclService } from './providers/service/hmcl.service';

import { LoadingController, ToastController, IonSlides, MenuController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { SessionStorageService } from './providers/SessionStorage/session-storage.service';
import { AlertsToastsService } from './providers/utils/alerts-toasts.service';


import { OtpPage } from './otp/otp.page';
import { OtpPageModule } from './otp/otp.module';
import { splitAtColon } from '@angular/compiler/src/util';
declare var window;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [RequestModelService, HmclService, OtpPage]
})
export class AppComponent {

  userLogin: boolean;
  adminLogin: boolean;
  role: string = "";
  navigate: any[] = [];
  isHrApp: boolean;
  userinfo = {
    name: '',
    ec_code: '',
    mobile: ''
  };
  constructor(
    public otp: OtpPage,

    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private javaScriptLoaderService: JavaScriptLoaderService,
    private request: RequestModelService,
    private router: Router,
    private hmclService: HmclService,
    private loader: LoadingController,
    private toast: ToastController,
    private storage: Storage,
    private sessionStorageService: SessionStorageService,
    private alertToast: AlertsToastsService) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();


      this.splashScreen.hide();

      this.sideMenu();
      this.sessionStorageService.getObservable().subscribe((data) => {
        // //console.log('Data received', data);
        if (data.foo == 'logout') {
          this.alertToast.showToast('Session expired, Please login again!', 3000).then(() => {
            this.Logout();
          });
        } else if (data.foo == 'resetMenu') {
          this.getUserInfo();
        }
      });
      this.getUserInfo();
    });
  }

  getUserInfo() {

    this.storage.get('token').then((token) => {
      if (token && token.name) {
        this.userinfo.name = token.name;
        this.userinfo.ec_code = token.code;
        this.userinfo.mobile = token.number;
        this.role = token.role;
      }
    });
  }

  sideMenu() {

    this.isHrApp = this.request.isHrApp;

    let navigate_tech = [
      {
        url: '/splash',
        title: 'Home',
        icon: 'home'
      },
      {
        url: 'reload',
        title: 'Reload App',
        icon: 'reload-circle'
      },
      {
        url: '/admin',
        title: 'Admin',
        icon: 'person-circle-outline',
        role: 'Admin'
      },
      {
        url: '/check-location-data',
        title: 'Check Location Data',
        icon: 'location-outline',
        role: 'Admin'
      },
      {
        url: '/admin',
        title: 'Admin',
        icon: 'person-circle-outline',
        role: 'HRHead'
      },
      {
        url: '/vaccine-info',
        title: 'Vaccine Info',
        icon: 'fitness-outline',
      },
      {
        url: '/check-location-data',
        title: 'Check Location Data',
        icon: 'location-outline',
        role: 'HRHead'
      }
      // {
      //   url: '/report',
      //   title: 'Report',
      //   icon: 'location-outline',
      //   role: 'HRHead'
      // }
    ];

    let navigate_hr = [
      {
        url: '/splash',
        title: 'Home',
        icon: 'home'
      },
      {
        url: 'reload',
        title: 'Reload App',
        icon: 'reload-circle'
      },
      {
        url: '/vaccine-info',
        title: 'Vaccine Info',
        icon: 'fitness-outline',
      },
      {
        url: '',
        title: 'Feedback',
        icon: 'thumbs-up'
      }
    ];

    let navigate_super_user = [
      {
        url: '/splash',
        title: 'Home',
        icon: 'home'
      },
      {
        url: 'reload',
        title: 'Reload App',
        icon: 'reload-circle'
      },
      {
        url: '/admin',
        title: 'Admin',
        icon: 'person-circle-outline',
        role: 'HRHead'
      },
       {
        url: '/vaccine-info',
        title: 'Vaccine Info',
        icon: 'fitness-outline',
      },
      {
        url: '/check-location-data',
        title: 'Check Location Data',
        icon: 'location-outline',
        role: 'HRHead'
      },
      {
        url: '/report',
        title: 'Report',
        icon: 'location-outline',
        role: 'HRHead'
      }
    ];

    this.navigate = this.request.isHrApp ? navigate_hr : navigate_tech;

  }

  // Goto(pages) {
  //   if (pages.url != '') {
  //     this.router.navigate([pages.url]);
  //   }
  // }

  Goto(pages) {
    if (pages.url == 'reload') {
      location.reload(true);
    } else if (pages.url != '') {
      //this.router.navigate([pages.url]);
      this.sessionStorageService.jumpToScreen(pages.url);
    }
  }

  Logout() {
    this.storage.get('token').then((token) => {
      this.loader.create({
        message: 'please wait'
      }).then((loadingElement) => {


        loadingElement.present();
        const REQ_DATA = this.request.getStaticRequestData();
        let out_process = REQ_DATA.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID = this.hmclService.ApiMapping.Portal_Logout;
        const PWDATA = this.request.sample_Portal_Logout();

        PWDATA.Portal_Logout.Row[0].mobile = token.number;
        REQ_DATA.PWSESSIONRS.PWPROCESSRS.PWDATA = PWDATA;


        this.hmclService.CallApi(REQ_DATA, token.tokentemp, token.number).subscribe((data: any) => {
          loadingElement.dismiss();
          this.storage.clear().then(() => {

            this.sessionStorageService.jumpToScreen('/splash');
          });


        }, (err) => {
          loadingElement.dismiss();
          this.storage.clear().then(() => {
            this.sessionStorageService.jumpToScreen('/splash');
          });
        });
      });

    });
  }

}
