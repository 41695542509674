import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';


declare var window;

// @Injectable()
@Injectable({
  providedIn: 'root'
})
export class HmclService {

  productionUrl: boolean = false;
  retailerCode: string = "";
  token: string = "";
  constructor(private http: HttpClient, public storage: Storage) {


  }


  ApiMapping = {
    'MB_Authentication': 'MB_Authentication',
    'MB_Verify_OTP_latest': 'MB_Verify_OTP_latest',
    'get_channel_content': 'get_channel_content',
    'Portal_Logout': 'Portal_Logout',
    'get_questions': 'get_questions',
    'get_ques_info_latest': 'get_ques_info_latest',
    'submit_evaluation': 'submit_evaluation',
    'get_history': 'get_history',
    'get_locations':'get_locations',
    'get_channel_content_token': 'get_channel_content_token',
    'get_banners':'get_banners',
    'get_questions_latest':'get_questions_latest',
  //  'check_submit_status_V2':'check_submit_status_V2',
    'update_profile':'update_profile',
    'get_emp_info':'get_emp_info',
    'update_declaration_status':'update_declaration_status',
    'get_location_wise_data_latest':'get_location_wise_data_latest',
    'update_mobile':'update_mobile',
    'get_submission_data':'get_submission_data',
    'AP_add_new_user':'AP_add_new_user',
    'hope_login': 'hope_login',
    'check_submit_status_v2':'check_submit_status_v2',
    'AP_get_dashboard_data':'AP_get_dashboard_data',
    'MB_User_RegistrationV2':'MB_User_RegistrationV2',
    'get_ques_for_header':'get_ques_for_header',

    'submit_vaccine_status':'submit_vaccine_status',
    'get_vaccine_ques':'get_vaccine_ques',
    'submit_dose_details':'submit_dose_details',
    'update_status_on_skip':'update_status_on_skip'
  }

  ReverseApiMapping = {
    'MB_Authentication': 'MB_Authentication',
    'get_emp_info':'get_emp_info',
    'get_ques_info_latest':'get_ques_info_latest',
    'MB_Verify_OTP_latest': 'MB_Verify_OTP_latest',
    'get_channel_content': 'get_channel_content',
    'Portal_Logout': 'Portal_Logout',
    'get_locations':'get_locations',
    'get_questions': 'get_questions',
    'submit_evaluation': 'submit_evaluation',
    'get_history': 'get_history',
    'get_channel_content_token': 'get_channel_content_token',
    'get_banners':'get_banners',
    'get_questions_latest':'get_questions_latest',
   // 'check_submit_status_V2':'check_submit_status_V2',
    'update_profile':'update_profile',
    'update_declaration_status':'update_declaration_status',
    'get_location_wise_data_latest':'get_location_wise_data_latest',
    'update_mobile':'update_mobile',
    'get_submission_data':'get_submission_data',
    'AP_add_new_user':'AP_add_new_user',
    'hope_login': 'hope_login',
    'check_submit_status_v2':'check_submit_status_v2',
    'AP_get_dashboard_data':'AP_get_dashboard_data',
    'MB_User_RegistrationV2':'MB_User_RegistrationV2',
    'get_ques_for_header':'get_ques_for_header',


    'submit_vaccine_status':'submit_vaccine_status',
    'get_vaccine_ques':'get_vaccine_ques',
    'submit_dose_details':'submit_dose_details',
    'update_status_on_skip':'update_status_on_skip'

  };

  ApiMapping_callType = {
    'get_emp_info':'1',
    'get_locations':'1',
    'get_location_wise_data_latest':'1',
    'update_declaration_status':'1',
    'get_ques_info_latest':'1',
    'MB_Authentication': '10',
    'MB_Verify_OTP_latest': '1',
    'get_channel_content': '16',
    'Portal_Logout': '1',
    'get_questions': '1',
    'submit_evaluation': '1',
    'get_history': '1',
    'get_channel_content_token': '16',
    'get_banners':'1',
   // 'check_submit_status_V2':'1',
    'update_profile':'1',
    'update_mobile':'1',
    'get_submission_data':'1',
    'get_questions_latest': '1',
    'AP_add_new_user':'1',
    'hope_login':'1',
    'check_submit_status_v2':'1',
    'AP_get_dashboard_data':'1',
    'MB_User_RegistrationV2':'1',
    'get_ques_for_header':'1',



    'submit_vaccine_status':'1',
    'get_vaccine_ques':'1',
    'submit_dose_details':'1',
    'update_status_on_skip':'1'

  };


  CallApi(body, token, mobile) {
    //////console.log(body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID);

    var process_id = body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID;
    var headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (process_id == this.ApiMapping.MB_Authentication || process_id == this.ApiMapping.MB_Verify_OTP_latest) {
      return this.CallApiFinal(body, headers);

    }
    else {
      var tokentemp = token + "::" + mobile + "::HMD";
      tokentemp = "Basic " + btoa(tokentemp);
      //console.log(tokentemp);

      var headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": tokentemp });
      body.PWSESSIONRS.PWPROCESSRS.PWHEADER.LOGIN_ID = mobile;
      return this.CallApiFinal(body, headers);
    }
  }


  CallApiFinal(body, headers,num?) {

    // console.log(JSON.stringify(body));
    var url = "";
    if(num==1)
    {
     url="https://mobapp.heromotocorp.com/api/cloudware_prod?ORG_ID=HEP";
    }
    else{
      url='https://mobapp.heromotocorp.com/api/cloudware_prod?ORG_ID=HMD'; 
    //  url = 'https://mobileapps.heromotocorp.com/cloudware_prod/GatewayAnalyserJson?ORG_ID=HMD'; //prod
    }
  

    body.PWSESSIONRS.PWPROCESSRS.PWHEADER.IN_PROCESS_ID = this.ApiMapping_callType[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID] || "";
    //to manage request sequence
    let row_length = body.PWSESSIONRS.PWPROCESSRS.PWDATA[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID].Row.length;
    let keys = row_length > 0 ? Object.keys(body.PWSESSIONRS.PWPROCESSRS.PWDATA[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID].Row[0]) : [];
    let orderedData = {};
    let unorderedData = row_length > 0 ? body.PWSESSIONRS.PWPROCESSRS.PWDATA[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID].Row[0] : {};
    // addding numbers before key to maintain sequesnce on server 
    for (var i = 0; i < keys.length; i++) {
      orderedData[i] = unorderedData[keys[i]];
    }
    body.PWSESSIONRS.PWPROCESSRS.PWDATA = {};
    body.PWSESSIONRS.PWPROCESSRS.PWDATA[this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID]] = {}
    body.PWSESSIONRS.PWPROCESSRS.PWDATA[this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID]].Row = [];
    body.PWSESSIONRS.PWPROCESSRS.PWDATA[this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID]].Row.push(orderedData);

    body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID = this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID];
    // console.log(JSON.stringify(body));
    return this.http.post(url, body, { headers: headers }).pipe((result)=>{
      
      return result;
    });
  }
  CallApi2(body) {
    var url = "";
    // var url = "http://10.200.2.231/PlatwareAnalyzer_03_01_65_uat/GatewayAnalyserJson?ORG_ID=HMM";
    var headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post(url, body, { headers: headers });

  }


  GetVideos(body, token, mobile) {

    var process_id = body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID;
    var headers = new HttpHeaders({ "Content-Type": "application/json" });
    var tokentemp = token + "::" + mobile + "::technician";
    tokentemp = "Basic " + btoa(tokentemp);

    var headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": tokentemp });
    body.PWSESSIONRS.PWPROCESSRS.PWHEADER.LOGIN_ID = mobile;
    return this.CallApiFinal(body, headers);
  }





}
