
import { Injectable } from '@angular/core';
import { OtpPage } from 'src/app/otp/otp.page';


// @Injectable()
@Injectable({
  providedIn: 'root'
})
export class RequestModelService {

   
  static isLive = true;
  static constant_app_version = 2.0;
  public isHrApp = false;
  public isMedApp = true;
  public APP_ID = this.isHrApp ? 'HHL' : (this.isMedApp ? 'HMD': 'HTL');
  public CHANNEL_ID = this.isHrApp ? '101' : '100';

  userdata: any;

  // constructor(private rest: Rest){
  constructor() {   
  }

  getStaticRequestDataTemp() {
    return {
      'PWSESSIONRS': {
        'PWPROCESSRS': {
          'PWHEADER': {
            'IN_PROCESS_ID': '16',
            'APP_ID': this.APP_ID,//"HTL",
            'ORG_ID': this.APP_ID,//"HTL",
            'OS_VERSION': '9',
            'OUT_PROCESS_ID': 'get_channel_content',
            'LOGIN_ID': 'KPOINT',
            'PASSWORD': ''
          },
          'PWDATA': {

          },
          'PWERROR': ''
        }
      }
    }
  }

  getStaticRequestData() {
    return {
      'PWSESSIONRS': {
        'PWPROCESSRS': {
          'PWHEADER': {
            'IN_PROCESS_ID': '',
            // 'APP_ID': this.APP_ID,//"HTL",
            // 'ORG_ID': this.APP_ID,//"HTL",
            'APP_ID': "HMD",
            'ORG_ID': "HMD",
            'OS_VERSION': '9',
            'OUT_PROCESS_ID': '',
            'LOGIN_ID': '',
            'PASSWORD': ''
          },
          'PWDATA': {

          },
          'PWERROR': ''
        }
      }
    }
  }

  getStaticRequestDataEmp() {
    return {
      'PWSESSIONRS': {
        'PWPROCESSRS': {
          'PWHEADER': {
            'IN_PROCESS_ID': '',
            // 'APP_ID': this.APP_ID,//"HTL",
            // 'ORG_ID': this.APP_ID,//"HTL",
            'APP_ID': "HEP",
            'ORG_ID': "HEP",
            'OS_VERSION': '9',
            'OUT_PROCESS_ID': '',
            'LOGIN_ID': '',
            'PASSWORD': ''
          },
          'PWDATA': {

          },
          'PWERROR': ''
        }
      }
    }
  }
  // sample_check_submit_status_V2() {
  //   return {
  //     'check_submit_status_V2': {
  //       'Row': [
  //         {
  //           'mobile': '',
  //           'token': ''
  //         }
  //       ]
  //     }
  //   }
  // }

  sample_check_submit_status() {
    return {
      'check_submit_status_v2': {
        'Row': [
          {
            'mobile': '',
            'token': ''
          }
        ]
      }
    }
  }
  sample_get_questions() {
    return {
      'get_questions': {
        'Row': [
          {
            'kapsule_id': '',
            'medium': ''


          }
        ]
      }
    }
  }
   sample_get_questions_new_info() {
    return {
      'get_ques_info_latest': {
        'Row': [
          {
            'admin_mobile': '',
            'user_ec':'',
            'search_user_mobile': '',
            'token': ''


          }
        ]
      }
    }
  } 

  sample_get_AP_add_new_user() {
    return {
      'AP_add_new_user': {
        'Row': [
          {
            'admin_mobile': '',
            'user_ec':'',
            'user_name':'',
            'user_location':'',
            'search_user_mobile': '',
            'token': ''


          }
        ]
      }
    }
  } 



  sample_get_submission_data() {
    return {
      'get_submission_data': {
        'Row': [
          {
            'admin_mobile': '',
            'search_date': '',
            'search_location': '',
            'declaration_type': '',
            'filled_type': '',
            'token': ''


          }
        ]
      }
    }
  }
  /* sample_update_declaration_status()
  {
    return {
      'update_declaration_status': {
        'Row': [
          {
            'admin_mobile': '',
            'search_user_mobile':'',
            'action':'',
            'token':''
            
            
          }
        ]
      }
    }
  } */

  sample_update_declaration_status() {
    return {
      'update_declaration_status': {
        'Row': [
          {
            'admin_mobile': '',
            'search_user_mobile': '',
            'action': '',
            'token': ''


          }
        ]
      }
    }
  }

  sample_update_mobile() {
    return {
      'update_mobile': {
        'Row': [
          {
            'admin_mobile': '',
            'old_mobile': '',
            'new_mobile': '',
            'token': ''


          }
        ]
      }
    }
  }


  sample_get_empInfo() {
    return {
      'get_emp_info': {
        'Row': [
          {

            'admin_mobile': '',
            'user_ec': '',
            'user_mobile': '',
            'token': ''


          }
        ]
      }
    }
  }

  sample_get_location() {
    return {
      'get_locations': {
        'Row': [
          {

            'admin_mobile': '',
            'token': ''


          }
        ]
      }
    }
  }


  sample_AP_get_dashboard_data() {
    return {
      'AP_get_dashboard_data': {
        'Row': [
          {


            'mobile':"",
            'date': '',
            'token': ''


          }
        ]
      }
    }
  }

  sample_get_location_wise_data() {
    return {
      'get_location_wise_data_latest': {
        'Row': [
          {

            'admin_mobile': '',
            'search_location': '',
            'search_data': '',
            'token': ''


          }
        ]
      }
    }
  }
  sample_get_banners() {
    return {
      'get_banners': {
        'Row': [
          {



          }
        ]
      }
    }
  }

  sample_update_profile() {
    return {
      'update_profile': {
        'Row': [
          {
            'mobile': '',
            'language': '',
            'sex': '',
            'age': '',
            'token': ''

          }
        ]
      }
    }
  }
  /*   sample_get_questions() {
      return {
        'get_questions': {
          'Row': [
            {
              'kapsule_id': '',
              'medium': ''
  
  
            }
          ]
        }
      }
    } */

   sample_get_questions_latest() {
     return {
       'get_questions_latest': {
         'Row': [
           {
             'kapsule_id': '',
             'medium': '',
             'token':''
 
 
           }
         ]
       }
     }
   } 
  /*  sample_get_banners() {
     return {
       'get_banners': {
         'Row': [
           {
 
 
 
           }
         ]
       }
     }
   } */
  sample_MB_Authentication() {
    return {
      'MB_Authentication': {
        'Row': [
          {
            'mobile': '',
            'appname': ''

          }
        ]
      }
    }
  }
  sample_get_history() {
    return {
      'get_history': {
        'Row': [
          {
            'userid': '',
            'mobile': ''


          }
        ]
      }
    }
  }
  sample_MB_Verify_OTP_latest() {
    return {
      'MB_Verify_OTP_latest': {
        'Row': [
          {
            'mobile': '',
            'otp': ''

          }
        ]
      }
    }
  }

  sample_submit_evaluation() {
    return {
      'submit_evaluation': {
        'Row': [
          {
            'userid': '',
            'mobile': '',
            'videoid': '',
            'videoname': '',
            'answers': '',
            'submit_type': '', // once /daily
            'token': ''

          }
        ]
      }
    }
  }
  sample_get_channel_content() {
    return {
      'get_channel_content': {
        'Row': [
          {
            'login_id': ''
          }
        ]
      }
    }
  }
  sample_get_channel_content_token() {
    return {
      'get_channel_content_token': {
        'Row': [
          {
            'login_id': ''
          }
        ]
      }
    }
  }


  sample_Portal_Logout() {
    return {
      'Portal_Logout': {
        'Row': [
          {
            'mobile': ''


          }
        ]
      }
    }
  }
  sample_hope_login() {
    return {
      'hope_login': {
        'Row': [
          {
            'mobile': '',
            'token':''
          }
        ]
      }
    }
  }

  sample_MB_validateToken() {
    return {
      'MB_User_RegistrationV2': {
        'Row': [
          {
            'token':'',
            'mobile': '',
            'source':''
            
          }
        ]
      }
    }
  }



  sample_get_ques_for_header() {
    return {
      'get_ques_for_header': {
        'Row': [
          {
            
            'mobile': '',
            'token':''
            
          }
        ]
      }
    }
  }


  sample_submit_vaccine_status() {
    return {
      'submit_vaccine_status': {
        'Row': [
          {
           


            'user_id': '',
            'age':'',
            'action':'',   
            'vac_status':'',               
            'reason': '',
            'expected_date':'',
            'token':''
            
          }
        ]
      }
    }
  }

  sample_get_vaccine_ques() {
    return {
      'get_vaccine_ques': {
        'Row': [
          {
            'user_id': '',
            "vc_no":'',
            'token':''
            
          }
        ]
      }
    }
  }


  sample_submit_dose_details() {
    return {
      'submit_dose_details': {
        'Row': [
          {
            'user_id': '',
            'vc_no':'',      
            'vc_name': '',   
            'date':'',       
            'vc_location': '',
            'token':''
            
          }
        ]
      }
    }
  }

  sample_update_status_on_skip() {
    return {
      'update_status_on_skip': {
        'Row': [
          {
            'user_id': ''
          }
        ]
      }
    }
  }
}