import { Component, OnInit } from '@angular/core';
import { HmclService } from '../providers/service/hmcl.service';
import { RequestModelService } from '../providers/request/request-model.service';
import { LoadingController, ToastController, MenuController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { Router } from "@angular/router";
import { SessionStorageService } from '../providers/SessionStorage/session-storage.service';

declare var isNumberKey;
@Component({
  selector: 'app-otp',
  templateUrl: './otp.page.html',
  styleUrls: ['./otp.page.scss'],
  providers: [HmclService, RequestModelService]
})
export class OtpPage implements OnInit {
  otp: string = "";
  tel: string = "";
  public role:boolean;
  constructor(private hmclService: HmclService, public request: RequestModelService, private loader: LoadingController, private toast: ToastController, private storage: Storage, private router: Router, private sessionStorageService: SessionStorageService, private menu: MenuController) { }



  ionViewDidEnter() {


    this.storage.get("token").then((token) => {
      this.menu.enable(false);
      if (token != null) {

      }
      else {
        this.sessionStorageService.jumpToScreen("/login");

      }

    }, (err) => {
      alert(err);

    })
  }
  ngOnInit() {
  }

  VerifyOtp() {
    if (this.otp != "") {
      this.storage.get(("token")).then((token) => {
        this.loader.create({
          message: "please wait"
        }).then((loadingElement) => {
          this.tel = token.number;

          loadingElement.present();
          let REQ_DATA = this.request.getStaticRequestData();
          let out_process = REQ_DATA.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID = this.hmclService.ApiMapping.MB_Verify_OTP_latest;
          let PWDATA = this.request.sample_MB_Verify_OTP_latest();

          PWDATA.MB_Verify_OTP_latest.Row[0].mobile = this.tel;
          PWDATA.MB_Verify_OTP_latest.Row[0].otp = this.otp;




          REQ_DATA.PWSESSIONRS.PWPROCESSRS.PWDATA = PWDATA;


          this.hmclService.CallApi(REQ_DATA, "", "").subscribe((data: any) => {
            loadingElement.dismiss();
            let datatemp = data.PWSESSIONRS[0].PWPROCESSRS.PWDATA.MB_Verify_OTP_latest.Row[0];
            this.toast.create({
              message: datatemp.message,
              duration: 3000
            }).then((toastElement) => {
              toastElement.present().then(() => {
                if (datatemp.success == "1") {  
                  token = datatemp;

                  token["tokentemp"] = datatemp.token;
                  token["number"] = datatemp.x_mobile;
                  token["profile"] = datatemp.profile_update;
                  token["role"] = datatemp.role;
                  token["homeScreen"] = '0';
                  this.otp = "";
                  this.storage.set("token", token).then(() => {
                   
                    this.sessionStorageService.publishSomeData({foo: 'resetMenu'});
                    //console.log("My token",token)
                    this.sessionStorageService.jumpToScreen('/splash');
                  })

                }
                else {
                 // this.storage.clear();

                }
              })
            })


          }, (err) => {
            loadingElement.dismiss();
            //this.sessionStorageService.publishSomeData({foo: 'logout'});
          });
        })
      })
    }
  }

}
