import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { resolve } from 'url';
import { NavController } from '@ionic/angular';
// import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor(private router: Router, private navCtrl: NavController) { }

  private isProd = true;

  private selectedPlaylist;
  private selectedVideo;
  private selectedTest;
  private submitStatus;
  public ONLY_ONCE_QID = ['1', '2', '9', '10', '12', '13', '14', '15', '16'];
  public setCurrentPlayList(jsonValue) {
    this.selectedPlaylist = jsonValue;
  }

  public getCurrentPlayList() {
    return this.selectedPlaylist;
  }

  public setSelectedVideo(jsonValue) {
    this.selectedVideo = jsonValue;
  }
  public getSelectedVideo() {
    return this.selectedVideo;
  }
  public setSelectedTest(jsonValue) {
    this.selectedTest = jsonValue;
  }
  public getSelectedTest() {
    return this.selectedTest;
  }
  public setSubmitStatus(jsonValue) {
    return new Promise((resolve, reject) => {
      this.submitStatus = jsonValue;
      resolve(true);
    });
  }
  public getSubmitStatus() {
    if(!this.submitStatus){
      this.submitStatus = {
        submit_type: '',
        submit_time: ''
      }
    }
    return this.submitStatus;
  }

  private fooSubject = new Subject<any>();

  publishSomeData(data: any) {
    this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
    return this.fooSubject;
  }

  public jumpToScreen(screenName) {
    // little delay so that values get set before page load
    /* setTimeout(() => {
      return this.router.navigate([screenName]);
    }, 100); */
    // this.navCtrl.navigateRoot(screenName);

    this.router.navigateByUrl(screenName, { skipLocationChange: true });

  }

  public getFormattedDate(strDate, currentFormat, requiredFormat){
    // return moment(strDate, currentFormat).format(requiredFormat);
  }
  public getFormattedDateNow(requiredFormat){
    // return moment().format(requiredFormat);
  }

}
